import * as React from "react"
import Layout from "../components/Layout";
import HeroImage from "../icons/HeroImage";
import Seo from "../components/Seo";
import {IconBetterSecurity, IconDecentralized, IconFocusedOnUsers, IconQuickDevelopment} from "../icons/Icons";
import ContactForm from "../components/ContactForm";
import {Sentence, Together, Accent, Accent2} from "../components/StyledComponents";
import YouTube from "../components/YouTube";
import {StaticImage} from "gatsby-plugin-image";

const data = {
    canonical: '/',
    title: 'Yachting.AI - AI for Yachting Industry'
}



const IndexPage = () => {
    return (
        <Layout breadcrumbs={true} breadcrumbsJustMeta={true} canonical={data.canonical}
                title={data.title}>
            <Seo
                title={"AI For Yachting"}
                lang={"en-US"}
                description={""}
                canonical={'https://Yachting.ai/'}
            />

            <section className={"relative hero bg-black h-screen flex items-end justify-center"}>
                <div className="absolute inset-0">
                    <StaticImage src={"../images/yachting-ai-hero-image-1.png"} alt={""} className="w-full h-75p"/>
                </div>
                <div className="z-10">
                    <h1 className={"text-cyan-500 text-[200px] text-center uppercase font-bold leading-[1] pb-10"}>Navigate
                        To<br/> Success</h1>
                    <p className={"text-cyan-300 text-2xl text-center pb-16"}>Revolutionizing the yachting experience
                        with cutting-edge AI technology,<br/>
                        bringing unparalleled precision and efficiency to your voyages.
                    </p>
                    <div className={"pb-24 text-center"}>
                        <input type={"text"} className={"bg-white rounded-full py-4 px-8 w-[500px]"}
                               placeholder={"Enter your email address"}/>
                        <button className={"bg-cyan-500 rounded-full py-4 px-8 ml-4 text-white"}>Get Early Access
                        </button>
                    </div>
                </div>
            </section>


            {/*<ScreenSizeHelper />*/}
            <section className={"px-6 py-16 max-container"}>
                <div className={"flex relative w-full lg:mt-36"}>
                    <div className={"flex flex-col justify-center w-1/2"}>
                        <h2 className={"text-var-6xl block mb-12 lg:w-full"}>AI Yacht ChatBot</h2>
                        <p className={"text-2xl xs:text-3xl my-4 col-start-1 row-start-2 lg:w-full lg:text-2xl"}>
                            <Sentence>Prepared and developed with particular care for the <b>yachting industry</b>, the
                                AI ChatBot can accurately respond to customers' very specific questions, and integration
                                with MLS allows for live display of yacht search results.</Sentence>
                        </p>
                    </div>
                    <div className={"w-1/2 ml-10"}>
                        <StaticImage src={"../images/yachting-ai-chatbot.png"} alt={""}/>
                    </div>
                </div>


                <div className={"flex 2xs:flex-row flex-col"}>
                    {/*<a href={"#contact"} className={"bg-accent font-black text-xl xs:text-2xl px-10 py-5 my-16"}>*/}
                    {/*    Talk to a Ninja*/}
                    {/*</a>*/}
                    {/*<a href={"#contact"}  className={"underline block text-link ml-10 text-xl xs:text-2xl place-self-center"}>Contact Us</a>*/}
                </div>
            </section>


            <section className={"px-6 py-16 bg-gray-900 h-screen md:h-auto"}>
                <div className={"max-container"}>
                    <div className={"flex relative w-full lg:mt-36"}>
                        <div className={"flex w-1/2"}>
                            <StaticImage src={"../images/yachting-ai-yacht-browser.png"} alt={""}/>
                        </div>
                        <div className={"w-1/2 ml-20 flex flex-col justify-center "}>
                            <h2 className={"text-var-6xl block mb-12 lg:w-full gradient-text-fire"}>AI Yacht Browser</h2>
                            <p className={"text-2xl xs:text-3xl my-4 col-start-1 row-start-2 lg:w-full lg:text-2xl text-gray-300"}>
                                <Sentence>
                                    A fresh and highly engaging approach to mobile browsing and searching for yachts.
                                    <br/><br/>
                                </Sentence>
                                <Sentence>
                                    It combines simplicity, intuitiveness, and an X factor that turns mobile searching from
                                    an irritating task into an enjoyable and engaging experience.
                                </Sentence>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className={"px-6 py-16 max-container"}>
                <div className={"flex relative w-full lg:mt-36"}>
                    <div className={"flex flex-col justify-center w-1/2"}>
                        <h2 className={"text-var-6xl block mb-12 lg:w-full"}>AI Yacht Search</h2>
                        <p className={"text-2xl xs:text-3xl my-4 col-start-1 row-start-2 lg:w-full lg:text-2xl"}>
                            <Sentence>Understand the user’s intent with the power of Natural Language Understanding
                                (NLU)</Sentence>
                            <br/><br/>
                            <Sentence>Coming soon.</Sentence>

                        </p>
                    </div>
                    <div className={"w-1/2 ml-10 flex flex-col justify-center"}>
                        <StaticImage src={"../images/yachting-ai-search.png"} alt={""}/>
                    </div>
                </div>
            </section>




            {/*<section className={"px-6 py-16 bg-alt-background h-screen md:h-auto"}>*/}
            {/*    <div className={"max-container"}>*/}
            {/*        <h2 className={"text-var-7xl text-left lg:text-center font-black mb-12 "} id={"what"}>What we&nbsp;do</h2>*/}
            {/*        <h3 className={"text-var-4xl font-black text-left lg:text-center"}>We create <Accent>high-performance</Accent>, <Accent>tightly*/}
            {/*            secured</Accent>,<br className={"hidden xl:block"} /> <Together>and <Accent>SEO-optimized</Accent></Together> <Together>Jamstack websites</Together>.</h3>*/}
            {/*        <p className={"xs:text-3xl 2xs:text-2xl text-xl pt-20 text-left lg:text-center"}>*/}
            {/*            Our team thoughtfully 🧠 listens to Google developers <br className={"hidden lg:block"} />*/}
            {/*            to deliver websites that best correspond with search <br className={"hidden lg:block"} />*/}
            {/*            engine signals as <Accent2>Core Web Vitals</Accent2> 🚀.</p>*/}
            {/*    </div>*/}
            {/*</section>*/}



            {/*<section className={"w-full h-full aspect-video"}>*/}
            {/*    <YouTube*/}
            {/*         src={"https://www.youtube.com/embed/S_tvd4bylPk"}*/}
            {/*         title={"Welcome to Gatsby Ninja"}*/}
            {/*         className={"w-full h-full aspect-video"} />*/}
            {/*</section>*/}

            <section className={"px-6 py-36 bg-gray-900 h-screen md:h-auto"}>
                <div className={"max-container"}>
                    <h2 className={"text-var-7xl text-left lg:text-center font-black mb-8 gradient-text-fire"}>Contact Us</h2>
                    <ContactForm/>
                </div>
            </section>

        </Layout>
    )
}

export default IndexPage